import { makeStyles } from '@material-ui/core/styles'
import type { Installation } from '@willig/types/api'
import { useGetOne } from 'src/hooks/useQueries'
import { SafeHtml } from 'src/libs/SafeHtml'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: '#FFAB0E',
    marginTop: '16px',
    padding: '16px',
    '& p:first-of-type': {
      fontWeight: 'bold',
    },
  },
  anomalies: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: '0 8px',
    '& p:first-of-type': {
      fontWeight: 'bold',
    },
  },
})

type AnomalieShowProps = {
  anomalies: any
}

export function AnomalieShow(props: AnomalieShowProps) {
  const { anomalies } = props
  const classes = useStyles()

  return (
    <div style={{ marginBottom: '24px' }}>
      {anomalies.length > 0 &&
        anomalies.map((anomalie: any) => {
          const arrayAnomalie = Object.entries(anomalie) as any
          const id = arrayAnomalie[0][0] as string
          return (
            <div key={arrayAnomalie[0][0]} className={classes.container}>
              <div className={classes.anomalies}>
                <InstallationName id={id} />
                <p style={{ margin: '0', fontWeight: 'bold' }}>
                  <SafeHtml>{arrayAnomalie[0][1].libelle_anomalies}</SafeHtml>
                </p>
              </div>
            </div>
          )
        })}
    </div>
  )
}

function InstallationName(props: { id: string }) {
  const { id } = props

  const { data: installation } = useGetOne<Installation>('Installation', {
    id: id,
  })
  return <>{installation && <p>{installation.data.libelle}</p>}</>
}
