import { useQuery, useMutation } from 'react-query'
import { antiCorruptionLayerProxy } from 'src/adapters/antiCorruptionLayerProxy'
import { DataProvider } from 'src/adapters/DataProvider'
import { useHttpClient } from 'src/adapters/nxHttpClient'

function useDataProvider() {
  const httpClient = useHttpClient()
  return antiCorruptionLayerProxy(DataProvider({ httpClient }))
}

export function useGetList<T>(
  resource: string,
  params?: any,
  queryParams?: any,
) {
  const client = useDataProvider()
  return useQuery<{ data: T[] }>(
    [resource, params],
    () => client.getList(`${resource}`, params),
    queryParams,
  )
}

export function useGetOne<T>(
  resource: string,
  params: { id: string | number | undefined },
  queryParams?: {},
) {
  const client = useDataProvider()
  return useQuery<{ data: T }>(
    [resource, params, queryParams],
    () => client.getOne(`${resource}`, { id: params.id || 0 }),
    queryParams,
  )
}
export function usePatch(resource: string, payload: {}, queryParams?: {}) {
  const client = useDataProvider()
  return useQuery(
    [resource, payload],
    () => client.patch(`${resource}`, payload),
    queryParams,
  )
}
export function usePut<T>(resource: string, payload: {}, queryParams?: {}) {
  const client = useDataProvider()
  return useQuery<{ data: T }>(
    [resource, payload],
    () => client.put(`${resource}`, payload),
    queryParams,
  )
}
export function usePutMutation(
  resource: string,
  payload: {},
  queryParams?: {},
) {
  const client = useDataProvider()
  return useMutation(
    [resource, payload],
    () => client.put(`${resource}`, payload),
    queryParams,
  )
}
export function useLogin(resource: string, payload: {}, queryParams?: {}) {
  const client = useDataProvider()
  return useQuery(
    [resource, payload],
    () => client.patch(`${resource}`, payload),
    queryParams,
  )
}
