import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  CircularProgress,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import type { File } from '@willig/types/api'
import { Env } from 'src/adapters/Env'
import { useGetOne, usePatch } from 'src/hooks/useQueries'

// eslint-disable-next-line @getify/proper-arrows/where
const StyledTableCell = withStyles((theme) => {
  return {
    head: {
      backgroundColor: 'lightgrey',
      color: theme.palette.common.black,
      fontSize: 16,
      fontWeight: 'bold',
    },
  }
})(TableCell)

type TableHistoricProps = {
  data: any
}

export function TableHistoric(props: TableHistoricProps) {
  const { data } = props

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>
              Informations utiles - Résumé des travaux réalisés
            </StyledTableCell>
            <StyledTableCell>Documents</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((intervention: any) => (
            <InterventionRow
              key={intervention.id}
              intervention={intervention}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type interventionRowProps = {
  intervention: any
}

function InterventionRow(props: interventionRowProps) {
  const { intervention } = props
  const { data: report } = usePatch('Intervention/reportDetail', {
    interventionId: intervention.id,
  })

  if (!report) return <CircularProgress />

  const etatDesLieux = report?.data.etat_des_lieux
    ? JSON.parse(report?.data.etat_des_lieux).pdfUUID
    : ''

  return (
    <TableRow key={intervention.id} style={{ backgroundColor: 'white' }}>
      <TableCell style={{ fontWeight: 'bold' }}>
        {new Date(intervention.rdv_date).toLocaleDateString('fr')}
      </TableCell>
      <TableCell>{}</TableCell>
      <TableCell>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {report?.data.bon_intervention && (
            <DocumentList
              id={report.data.bon_intervention}
              label={"bon d'intervention"}
            />
          )}
          {report?.data.certificat && (
            <DocumentList id={report?.data.certificat} label={'certificat'} />
          )}
          {etatDesLieux.length > 0 && (
            <>
              {etatDesLieux.map((document: any, key: number) => {
                return (
                  <DocumentList
                    key={key}
                    id={document}
                    label={`etat des lieux`}
                  />
                )
              })}
            </>
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}

function DocumentList(props: { id: string; label: string }) {
  const { id, label } = props

  return <DownloadDocument id={id} label={label} />
}

function DownloadDocument(props: { id: string; label: string }) {
  const { id, label } = props
  const env = Env()
  const { data: file } = useGetOne<File>('File', { id })
  return (
    <a
      target="_blank"
      href={`${env.API_URL}/${file?.data.path}`}
      rel="noreferrer"
    >
      {label}
    </a>
  )
}
